import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Stack,
  useBreakpointValue,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { useDebouncedValue, useViewportSize } from '@mantine/hooks'
import { GoogleMap, Marker } from '@react-google-maps/api'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiTargetLock } from 'react-icons/bi'
import { FaFilter } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import useSWR from 'swr'
import IconCE7 from '../assets/images/markers/CE7.svg'
import IconCT7 from '../assets/images/markers/CT7.svg'
import DriverDetailsHome from '../components/DriverDetailsHome'
import Drivers from '../components/Drivers'
import LanguageSelector from '../components/LanguageSelector'
import { useGeolocation } from '../contexts/GeolocationContext'
import api from '../lib/api-client'
import APIForSixDigit from '../lib/api-client'
import { snazzyMapStyle } from '../lib/map-style'
import { DRIVER_GENDER, VEHICLE_TYPE } from '../utils/config'


const containerStyle = {
  width: '100%',
  height: '100%',
}

const center = {
  lat: 33.4489,
  lng: 70.6693,
}

export default function HomePage() {
  const { t } = useTranslation()
  const toast = useToast()
  const selectSize = useBreakpointValue({ base: 'xs', md: 'md' })
  const { position, error } = useGeolocation()

  const [filteredDrivers, setFilteredDrivers] = useState([])
  const [vehicleType, setVehicleType] = useState('') //Empty means all
  const [passengerCapacity, setPassengerCapacity] = useState('') //'' means all
  const [acceptCreditCard, setAcceptCreditCard] = useState('2') //2 means both
  const [gender, setGender] = useState(DRIVER_GENDER.all)
  const [speaksEnglish, setSpeaksEnglish] = useState(false)
  const [subAdminCode, setSubAdminCode] = useState('')
  const [isActive, setIsActive] = useState('ALL') //Empty means all
  const [code, setCode] = useState('');
  const [markerPosition, setMarkerPosition] = useState({
    lat: '',
    lng: ''
  })
  const [map, setMap] = useState(
    /** @type google.maps.Map*/
    (null)
  )
  const [currentLocation, setCurrentLocation] = useState(center)
  const [selectedDriver, setSelectedDriver] = useState(null)
  const [mapCenter, setMapCenter] = useState({ latitude: 0, longitude: 0 })
  const [debouncedMapCenter] = useDebouncedValue(mapCenter, 500)

  const onMapLoad = useCallback(map => {
    setMap(map)
  }, [])

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        setMarkerPosition({
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
        })
        setCurrentLocation({
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
        })
      },
      (err) => console.error(err),
      { enableHighAccuracy: true }
    );
  }, []);  

  useEffect(() => {
    localStorage.setItem('is-booking-grua', JSON.stringify(false))
    console.log('debouncedMapCenter', debouncedMapCenter)
  }, [debouncedMapCenter])

  const { height, width } = useViewportSize()

  const { data, error: apiError } = useSWR(
    debouncedMapCenter
      ? `/near-me-drivers?lng=${debouncedMapCenter.longitude}&lat=${debouncedMapCenter.latitude}`
      : null,
    api
  )

  useEffect(() => {
    console.log('data', data)
    // console.log('Position', position)
  }, [data])

  // console.log('api defaults', api.defaults.baseURL)

  // useEffect(() => {
  //   console.log('Position', position)
  //   if (position != null && map != null) {
  //     map.panTo({
  //       lat: position.coords.latitude,
  //       lng: position.coords.longitude,
  //     })
  //   }
  // }, [position, map])

  
  useEffect(() => {
    let filtered = data ?? []
    // console.log(passengerCapacity, vehicleType, acceptCreditCard, gender)
    if (vehicleType !== '' && vehicleType !== 'ALL') {
      filtered = filtered.filter(driver => driver.vehicle.type === vehicleType)
    }
    if (acceptCreditCard === '1') {
      filtered = filtered.filter(driver => driver.acceptCreditCard === true)
    } else if (acceptCreditCard === '0') {
      filtered = filtered.filter(driver => driver.acceptCreditCard === false)
    }
    if (passengerCapacity !== '') {
      filtered = filtered.filter(
        driver => driver.vehicle.passengerCapacity >= passengerCapacity
      )
    }
    if (gender !== DRIVER_GENDER.all) {
      filtered = filtered.filter(driver => driver.gender === gender)
    }
    if (speaksEnglish === true) {
      filtered = filtered.filter(driver => driver.speaksEnglish === true)
    }
    if (subAdminCode !== '') {
      filtered = filtered.filter(driver => driver.subAdminCode === subAdminCode)
    }
    if(isActive === "true"){
      filtered = filtered.filter(driver => driver.isAvailable === true)
    }else{
      filtered = filtered.filter(driver => driver.isAvailable === false)
    }
    
    if(isActive === "ALL"){
      filtered = data ?? []
    }
    setFilteredDrivers(filtered)
  }, [
    vehicleType,
    passengerCapacity,
    subAdminCode,
    acceptCreditCard,
    gender,
    speaksEnglish,
    data,
    isActive
  ])

  useEffect(() => {
    console.log('filteredLength', filteredDrivers.length)
  }, [filteredDrivers])

  const firstModal = useDisclosure();
  const secoundModal = useDisclosure();

  function handleCurrentLocation() {
    if (markerPosition.lat && markerPosition.lng && map) {
      map.panTo({ lat: markerPosition.lat, lng: markerPosition.lng });
    }
  }

  // console.log('filtered drivers', filteredDrivers)

  const handlesixcodeSubmit = async () => {
    if (/^\d{6}$/.test(code)) {
      try {
        // Handle valid six-digit code submission
        const response = await api.get(`/six-digit-code/${code}`);
        console.log("response lat lng--->", response);
  
        // Assuming the API response contains lat and lng
        let center = {
          lat: response.lat,
          lng: response.lng,
        };
  
        setCurrentLocation(center);
        setMarkerPosition({
          lat: center.lat,
          lng: center.lng,
        });
  
        toast({
          title: 'Code accepted.',
          description: `Your six-digit code has been submitted successfully: ${code}`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
  
        setCode('');
        secoundModal.onClose();
      } catch (error) {
        console.error('Error submitting the six-digit code:', error);
  
        toast({
          title: 'Wrong code',
          description: 'Enter correct six-digit code',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: 'Invalid code.',
        description: 'Please enter a valid six-digit code.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };  


  return (
    // <VStack w='100vw' h='100vh'>
    <VStack w={width} h={height}>
      <Box boxSize={'100%'} position='absolute' top={0} left={0}>
        <GoogleMap
          onCenterChanged={() =>
            map &&
            setMapCenter({
              latitude: map.getCenter().lat(),
              longitude: map.getCenter().lng(),
            })
          }
          onLoad={onMapLoad}
          id='direction-example'
          mapContainerStyle={containerStyle}
          zoom={process.env.NODE_ENV === 'production' ? 15 : 10}
          center={currentLocation}
          options={{
            zoomControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            clickableIcons: false,
            styles: snazzyMapStyle,
          }}
        >
          {position && (
            <Marker
              position={markerPosition}
            />
          )}
          {filteredDrivers && (
            <Drivers
              setSelectedDriver={setSelectedDriver}
              drivers={filteredDrivers}
            />
          )}
        </GoogleMap>
      </Box>
      <HStack alignSelf='stretch' justifyContent='space-between' px={2}>
        <IconButton
          aria-label='current-location'
          colorScheme={'primary'}
          icon={<FaFilter />}
          onClick={firstModal.onOpen}
        />
        <ButtonGroup colorScheme='primary'>
          <Button as='a' href={t('helpUrl')} target='_blank'>
            {t('page1.help')}
          </Button>
          <Menu>
          <MenuButton as={Button}>{t('page1.findTaxi')}</MenuButton>
          <MenuList>
            <Link to='/booking'><MenuItem> {t('page2.BookingNow')} </MenuItem></Link>
            <Link to='/booking-later'><MenuItem> {t('page2.BookingLater')} </MenuItem></Link>
          </MenuList>
        </Menu>
          <Link to='/booking-grua'>
            <Button colorScheme='red'>{t('page1.findGrua')}</Button>
          </Link>
          <Button colorScheme='primary' onClick={secoundModal.onOpen} >
            {t('filter.SixDigitCode')}
          </Button>
        </ButtonGroup>
        <VStack>
          <LanguageSelector />
          <IconButton
            aria-label='current-location'
            colorScheme={'primary'}
            icon={<BiTargetLock />}
            onClick={handleCurrentLocation}
          />
        </VStack>
      </HStack>
      <Modal isOpen={firstModal.isOpen} onClose={firstModal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('filter.filter')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack
              bgColor='white'
              padding={4}
              borderRadius='lg'
              zIndex='modal'
              spacing={{ base: 2, md: 8 }}
            >
              {/* <FormControl id="six-digit-code">
                <FormLabel>{t('filter.SixDigitCode')}</FormLabel>
                <Input
                  type="text"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  maxLength={6}
                />
              </FormControl> */}
              <FormControl>
                <FormLabel fontSize={{ base: 'sm', md: 'md' }}>
                  {/* Taxi type */}
                  {t('filter.taxiType')}
                </FormLabel>
                <Select
                  onChange={e => setVehicleType(e.target.value)}
                  defaultValue={vehicleType}
                >
                  <option value='ALL'>{t('filter.all')}</option>
                  <option value={VEHICLE_TYPE.TAXI}>Taxi</option>
                  <option value={VEHICLE_TYPE.EXECUTIVE_TAXI}>Premium</option>
                  <option value={VEHICLE_TYPE.GRUA}>Grua</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel fontSize={{ base: 'sm', md: 'md' }}>
                  {/* Taxi type */}
                  {t('filter.taxiType')}
                </FormLabel>
                <Select
                  onChange={e => setIsActive(e.target.value)}
                  defaultValue={isActive}
                >
                  <option value='ALL'>All</option>
                  <option value='true'>Active</option>
                  <option value='false'>Inactive Taxis</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel fontSize={{ base: 'sm', md: 'md' }}>
                  {/* Minimum Passengers */}
                  {t('filter.minimumPassengers')}
                </FormLabel>
                <Input
                  type='number'
                  name='passengers'
                  id='passengers'
                  value={passengerCapacity}
                  onChange={e => {
                    if (e.target.value === '') {
                      setPassengerCapacity('')
                      return
                    }
                    setPassengerCapacity(parseInt(e.target.value))
                  }}
                />
              </FormControl>
              <FormControl>
                <FormLabel fontSize={{ base: 'sm', md: 'md' }}>
                  {/* Accepts credit card */}
                  {t('filter.acceptCreditCard')}
                </FormLabel>
                <RadioGroup
                  value={acceptCreditCard}
                  onChange={setAcceptCreditCard}
                >
                  <Stack direction='row'>
                    {/* <Radio value='1'>Yes</Radio>
                    <Radio value='0'>No</Radio>
                    <Radio value='2'>Both</Radio> */}
                    <Radio value='1'>{t('filter.yes')}</Radio>
                    <Radio value='0'>{t('filter.no')}</Radio>
                    <Radio value='2'>{t('filter.all')}</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>

              <FormControl>
                <FormLabel fontSize={{ base: 'sm', md: 'md' }}>
                  {/* Gender */}
                  {t('filter.gender')}
                </FormLabel>
                <RadioGroup value={gender} onChange={setGender}>
                  <Stack direction='row'>
                    <Radio value={DRIVER_GENDER.all}>
                      {/* {DRIVER_GENDER.all} */}
                      {t('filter.all')}
                    </Radio>
                    <Radio value={DRIVER_GENDER.male}>
                      {/* {DRIVER_GENDER.male} */}
                      {t('filter.male')}
                    </Radio>
                    <Radio value={DRIVER_GENDER.female}>
                      {/* {DRIVER_GENDER.female} */}
                      {t('filter.female')}
                    </Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
              <Checkbox
                size='lg'
                alignSelf='flex-start'
                onChange={e => setSpeaksEnglish(e.target.checked)}
              >
                {t('filter.englishSpoken')}
              </Checkbox>
            </VStack>
          </ModalBody>
          <ModalFooter>
            {/* <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            {t('filter.Submit')}
            </Button> */}
            <Button
              onClick={() => {
                setAcceptCreditCard('2')
                setPassengerCapacity('')
                setVehicleType('')
                setGender(DRIVER_GENDER.all)
              }}
            >
              {/* Clear filter */}
              {t('filter.clearFilter')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={secoundModal.isOpen} onClose={secoundModal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('filter.filter')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack
              bgColor='white'
              padding={4}
              borderRadius='lg'
              zIndex='modal'
              spacing={{ base: 2, md: 8 }}
            >
              <FormControl id="six-digit-code">
                <FormLabel>{t('filter.SixDigitCode')}</FormLabel>
                <Input
                  type="text"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  maxLength={6}
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handlesixcodeSubmit}>
            {t('filter.Submit')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <DriverDetailsHome
        driver={selectedDriver}
        isOpen={selectedDriver}
        onClose={() => setSelectedDriver(null)}
      />
    </VStack>
  )
}

function generateTaxis(position, setSelectedDriver) {
  if (!position) {
    return
  }
  return (
    <>
      {Array.from({ length: 50 }).map((value, index) => {
        const direction = Math.random() < 0.5 ? -20 : 20
        // console.log(position.coords.latitude + Math.random() / direction)
        // console.log(position.coords.longitude + Math.random() / direction)
        const coords = {
          lat: position.coords.latitude + Math.random() / direction,
          lng: position.coords.longitude + Math.random() / direction,
        }
        console.log(coords)
        return (
          <Marker
            key={index}
            position={coords}
            icon={Math.random() < 0.5 ? IconCE7 : IconCT7}
            onClick={value => setSelectedDriver({ ...coords })}
          />
        )
      })}
    </>
  )
}
